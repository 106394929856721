import React from 'react';
import styled from 'styled-components';
import {useOutlet, getOutlet} from 'reconnect.js';
import EditingGroup from './EditingGroup';
import * as EditingUtil from '../../Utils/EditRecordUtil';
import {Button, message, Collapse} from 'antd';
import {PROVIDER} from '../../Constants';
import {UpdateApi} from './UpdateApi';
import {PageTitle} from '../../Components/Widgets';
import getSuggestedPrice from '../../Utils/SuggestPriceCalc';

function editingDataToJstorageProductData(editingRecord) {
  let result = {...editingRecord};
  delete result.provider;
  delete result.inventory;
  delete result.retention;
  delete result.updated;
  return result;
}

function cleanUpEditingDataToProviderRecord(editingRecord) {
  let result = {...editingRecord};
  delete result.shipping_cost; // legacy data
  delete result.cost; // legacy data
  delete result.delivery_id;
  return result;
}

function mergeProviderDataToInternalProductData(internalProduct, dataList) {
  let result = {
    ...internalProduct,
    cost: _toValidNumber(internalProduct.cost),
    shipping_cost: _toValidNumber(internalProduct.shipping_cost),
  };
  function _toValidNumber(v) {
    let _v = Number(v);
    return isNaN(_v) ? 0 : _v;
  }
  for (let key of Object.keys(PROVIDER)) {
    if (internalProduct[`${key}_id`]) {
      let found = dataList.find(
        (d) => d.id === internalProduct[`${key}_id`] && d.provider === key,
      );
      result[key] = found
        ? {
            delivery_id: found.delivery_id || '',
          }
        : {};
    }
  }
  return result;
}

function Editing(props) {
  const [records] = useOutlet('editing');
  const [actions] = useOutlet('actions');
  const [siteConfig, setSiteConfig] = useOutlet('site-config');
  // don't remove this, this is used to allow re-rendering
  const [beforeRecords] = useOutlet('beforeEditing');

  async function updateProviderData(updateContent) {
    let errorList = [];
    let successList = [];

    try {
      for (let record of updateContent) {
        try {
          let updater = UpdateApi[record.provider];
          if (!updater) {
            throw `updater not found: ${record.provider}`;
          }

          let resp = await updater({
            id: record.id,
            stock: record.stock,
            price: record.price,
            name: record.name,
          });
          successList.push({record, success: resp});
        } catch (err) {
          errorList.push({record, error: err});
        }
      }

      console.log('errorList', errorList);
      console.log('successList', successList);
      message.success('平台資料已成功更新');
    } catch (ex) {
      console.warn(ex);
    } finally {
    }
  }

  const isAllProviderPriceValid = function (_records, internalRecord) {
    let records = _records.filter((r) => r.provider !== 'internal');
    const platformFeesObj = {};

    for (let i = 0; i < records.length; i++) {
      let record = records[i];
      if (!platformFeesObj[record.provider]) {
        platformFeesObj[record.provider] =
          siteConfig[`${record.provider}_fees`] || [];
      }

      let platformFees = siteConfig[`${record.provider}_fees`] || [];
      let suggestedPrice = getSuggestedPrice(
        internalRecord.cost,
        internalRecord.shipping_cost,
        platformFees,
      );

      if (suggestedPrice > record.price) {
        return false;
      }
    }

    return true;
  };

  const onSave = async function () {
    const _editingRecords = getOutlet('editing').getValue();

    for (let internalRecord of _editingRecords.filter(
      (r) => r.provider === 'internal',
    )) {
      let records = EditingUtil.getGroupRecords(internalRecord);
      if (!isAllProviderPriceValid(records, internalRecord)) {
        message.error('商品價格必須大於等於建議售價');
        return;
      }
    }

    try {
      actions.setLoading(true);
      let _internalProducts = _editingRecords
        .filter((r) => r.provider === 'internal')
        .map(editingDataToJstorageProductData);
      _internalProducts = _internalProducts.map((p) =>
        mergeProviderDataToInternalProductData(p, _editingRecords),
      );
      const bulkWriteOperations = _internalProducts.map((p) => ({
        method: 'update_one',
        payload: {
          query: {id: p.id},
          data: p,
        },
      }));

      await actions.bulkWriteDocuments('product', bulkWriteOperations);

      const _updateProviderRecord = _editingRecords
        .filter((r) => r.provider !== 'internal')
        .map(cleanUpEditingDataToProviderRecord);
      await updateProviderData(_updateProviderRecord);
      message.success('已成功更新');
      // EditingUtil.saveToStorage({clear: true});
      getOutlet('beforeEditing').update(_editingRecords);
    } catch (err) {
      console.log(err);
      message.error('無法更新');
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <PageTitle>編輯商品</PageTitle>

        <Button
          style={{marginLeft: 10}}
          disabled={records.length === 0}
          onClick={() => onSave()}>
          確認編輯
        </Button>

        <Button
          style={{marginLeft: 10}}
          onClick={() => {
            EditingUtil.saveToStorage();
            message.success('已成功暫存');
          }}>
          暫存
        </Button>

        <Button
          style={{marginLeft: 10}}
          onClick={() => {
            const yes = window.confirm('確定嗎？');
            if (yes) {
              EditingUtil.saveToStorage({clear: true});
              message.success('已成功清除');
            }
          }}>
          清除所有記錄
        </Button>
      </div>

      {records.length === 0 && <h2>目前無編輯中的商品</h2>}

      {records.length !== 0 && (
        <Collapse defaultActiveKey={[records[0].id]}>
          {records
            .filter((record) => record.provider === 'internal')
            .map((record, idx) => {
              return (
                <Collapse.Panel
                  header={`${idx + 1}. ${record.name}`}
                  key={record.id}>
                  <EditingGroup internalRecord={record} />
                </Collapse.Panel>
              );
            })}
        </Collapse>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default Editing;
