import {getOutlet} from 'reconnect.js';
import Config from '../../../data.json';
import {req} from '../../Utils/ApiUtils';
import moment from 'moment';

const UserOutlet = getOutlet('user');
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mock = false;
const TEST_ID = {
  shopee: 1656024,
  rakuten: 'test_sku1006',
  yahoomall: 'p073240849215',
  momomall: '1008290018803',
  momocenter: '8215284', // 非測試商品 / OrderFakeData 0000001 寫假的
  yahoo: '26551099', // 非測試商品 / OrderFakeData 10000099 寫假的
};

async function rakutenUpdate({id, stock, price}) {
  if (mock) {
    id = TEST_ID.rakuten;
  }

  const priceResp = await req(
    `${Config.apiHost}/rakuten/update/price?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        baseSku: id,
        marketplaceIdentifier: 'tw',
        shopUrl: 'ya-kuang',
        sku: id,
        value: price,
      },
    },
  );

  const {
    operations: [priceOperResp],
  } = await req(
    `${Config.apiHost}/rakuten/get/operation?token=${
      UserOutlet.getValue().token
    }&operation_id=${priceResp.operationId}`,
    {
      method: 'POST',
    },
  );

  const stockResp = await req(
    `${Config.apiHost}/rakuten/update/stock?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        sku: id,
        inventory: {
          quantity: stock,
          returnQuantityOnCancel: true,
        },
      },
    },
  );

  const {
    operations: [stockOperResp],
  } = await req(
    `${Config.apiHost}/rakuten/get/operation?token=${
      UserOutlet.getValue().token
    }&operation_id=${stockResp.operationId}`,
    {
      method: 'POST',
    },
  );

  return {
    stock: {
      result: stockOperResp.successCount === 1,
      data: stockOperResp,
    },
    price: {
      result: priceOperResp.successCount === 1,
      data: priceOperResp,
    },
  };
}

async function yahoomallUpdate({id, stock, price, name}) {
  if (mock) {
    id = TEST_ID.yahoomall;
  }
  const detailResp = await req(
    `${Config.apiHost}/yahoo-mall/product/detail?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        ProductId: id,
      },
    },
  );

  const {
    Response: {Product: product},
  } = detailResp;

  const priceResp = await req(
    `${Config.apiHost}/yahoo-mall/update/price?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        ProductId: id,
        SaleType: product.SaleType,
        ProductName: product._ProductName,
        MallCategoryId: product.MallCategoryId,
        MarketPrice: product.MarketPrice, // 市價
        SalePrice: price, // 網路價（售價）
        CostPrice: product.CostPrice, // 採購成本
        MaxBuyNum: product.MaxBuyNum,
        ShortDescription: product._ShortDescription || '',
        PayTypeId: product.PayTypeIdList.PayTypeId[0]?.['@Id'] || 1,
        ShippingId: product.ShippingIdList.ShippingId[0]?.['@Id'] || 2,
      },
    },
  );

  // yahoo mall have to manual make it online again, since update price will make product offline (unknow why)
  if (product.ProductStatus === 'Online') {
    const makeProductOnlineResp = await req(
      `${Config.apiHost}/yahoo-mall/product/online?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data: [id],
      },
    );
  }

  const spec = product.SpecList.Spec[0] || {};
  const isAdd = stock >= spec.CurrentStock;
  const stockDiff = Math.abs(stock - spec.CurrentStock);

  const stockResp = await req(
    `${Config.apiHost}/yahoo-mall/update/stock?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        ProductId: id,
        Id: spec['@Id'] || '1',
        Action: isAdd ? 'add' : 'del', // del or add
        Stock: stockDiff,
        SaftyStock: spec.SaftyStock,
      },
    },
  );

  return {
    price: {result: priceResp.Response['@Status'] === 'ok', data: priceResp},
    stock: {result: stockResp.Response['@Status'] === 'ok', data: stockResp},
  };
}

async function momomallUpdate({id, name, stock, price}) {
  if (mock) {
    id = TEST_ID.momomall;
  }
  const detailResp = await req(
    `${Config.apiHost}/momomall/product/detail?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        goodsCode: id,
      },
    },
  );

  const product = detailResp.outputObject;
  const startDateDataArr = moment()
    .add(5, 'minutes')
    .format('YYYY/MM/DD::HH::mm')
    .split('::');

  const priceResp = await req(
    `${Config.apiHost}/momomall/update/price?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        goodsInfoArray: [
          {
            goodsCode: id,
            goodsName: product.goodsName,
            pricesInfoArray: [
              {
                applyDate: startDateDataArr[0],
                applyDateHH: startDateDataArr[1],
                applyDateMM: startDateDataArr[2],
                custPrice: product.custPrice[0] || '',
                salePrice: price,
                salePriceCheck: price,
                buyPrice: product.buyPrice[0] || '',
              },
            ],
          },
        ],
      },
    },
  );

  const stockResp = await req(
    `${Config.apiHost}/momomall/update/stock?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        updateStockInfo: [
          {
            goodsCode: id,
            goodsdtCode: product.goodsdtCode[0], // 單品編號
            safeQty: product.safeQty[0],
            canSaleQty: `${stock}`,
          },
        ],
      },
    },
  );

  return {
    price: {
      result: priceResp.errorMessageList.length === 0,
      data: priceResp.outputObject,
    },
    stock: {
      result: stockResp.errorMessageList.length === 0,
      data: stockResp.outputObject,
    },
  };
}

async function shopeeUpdate({id, name, stock, price}) {
  if (mock) {
    id = TEST_ID.shopee;
  }

  const detailResp = await req(
    `${Config.apiHost}/shopee/product/detail?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        item_id_list: [id],
      },
    },
  );

  const product = detailResp.response.item_list[0];

  if (product.has_model) {
    return {
      priceResp: {result: false, data: 'shopee 不支援編輯 has_model 商品'},
      stockResp: {result: false, data: 'shopee 不支援編輯 has_model 商品'},
    };
  }

  const priceResp = await req(
    `${Config.apiHost}/shopee/update/price?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        item_id: id,
        price_list: [
          {
            model_id: 0,
            original_price: price,
          },
        ],
      },
    },
  );

  const stockResp = await req(
    `${Config.apiHost}/shopee/update/stock?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        item_id: id,
        stock_list: [
          {
            model_id: 0,
            normal_stock: stock,
          },
        ],
      },
    },
  );

  return {
    price: {
      result: priceResp.response.failure_list.length === 0,
      data: priceResp.response,
    },
    stock: {
      result: stockResp.response.failure_list.length === 0,
      data: stockResp.response,
    },
  };
}

async function momocenterUpdate({id, name, stock, price}) {
  // FIXME: 沒有 price? // 這格要小心，庫存不為0 他就會被自動上架～ (目前無測試商品?
  if (mock) {
    id = TEST_ID.momocenter;
  }

  const listResp = await req(
    `${Config.apiHost}/momocenter/product/list?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        goodsCode: id,
        toDate: moment().format('YYYY-MM-DD'),
      },
    },
  );

  const productFromList = listResp.dataList[0];

  const detailResp = await req(
    `${Config.apiHost}/momocenter/product/detail?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        supGoodsCode: productFromList.supGoodsCode,
      },
    },
  );

  return;

  const stockResp = await req(
    `${Config.apiHost}/momocenter/update/stock?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        goodsCode: id,
        goodsName: productFromList.GOODS_NAME,
        goodsdtCode: productFromList.GOODSDT_CODE,
        goodsdtInfo: '',
        orderCounselQty: stock,
        addReduceQty: -1, // ?
      },
    },
  );

  return {
    stock: stockResp,
    price: {result: false, data: 'momocenter 不支援更改價錢'},
  };
}

async function yahoocenterUpdate({id, name, price, stock}) {
  if (mock) {
    id = TEST_ID.yahoo; // 注意：非測試商品
  }

  const detailResp = await req(
    `${Config.apiHost}/yahoo/stock/get?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        ProductId: id,
      },
    },
  );

  const diffQty = stock - detailResp.Qty;

  const stockResp = await req(
    `${Config.apiHost}/yahoo/update/stock?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        ProductId: id,
        Qty: diffQty,
      },
    },
  );

  return {
    price: null,
    stock: {result: stockResp.UpdateQty === diffQty, data: stockResp},
  };
}

const UpdateApi = {
  rakuten: rakutenUpdate, // ok
  yahoomall: yahoomallUpdate, // ok
  // momomall: momomallUpdate, // ok
  shopee: shopeeUpdate, // ok
  // momocenter: momocenterUpdate, // by pass
  // yahoo: yahoocenterUpdate, // by pass // price: x; stock ok
};

export {UpdateApi};
